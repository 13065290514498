<template>
  <div class="main-container">
    <h5 class="pageTitle">정규 메세지 수정/삭제</h5>

    <div class="form-container">
      <div class="form-group">
        <label for="msgName">템플릿명</label>
        <input
          type="text"
          id="msgName"
          v-model="editableName"
          class="input-box"
        />
      </div>

      <div class="form-group">
        <label for="msgContent">템플릿내용</label>
        <div class="template-vars">
          <span @click="insertVariable('${name}')" class="template-tag">이름</span>
          <span @click="insertVariable('${model}')" class="template-tag">모델(타입)</span>
          <span @click="insertVariable('${enddate}')" class="template-tag">만료일1</span>
          <span @click="insertVariable('${YY}.${MM}.${DD}')" class="template-tag">만료일2</span>
        </div>
        <textarea
          class="textarea-box"
          v-model="editableContent"
          @keydown="onKeyDown"
          @click="onClickTextarea"
          @input="onInput"
          rows="2"
        ></textarea>
        <div style="text-align: right; margin-top: 4px;">
          ({{ currentByte }}/{{ byteLimit }} byte)
        </div>
      </div>

      <!-- <div class="button-group">
        <button @click="goBack"  type="button" class="btn btn-warning">목록</button>
        <button @click="updateMessage" class="btn btn-primary">수정</button>
        <button @click="deleteMessage" class="btn btn-danger">삭제</button>
      </div> -->
      <div class="button-container">
  <button
    type="button"
    class="button-list"
    @click="goBack"
  >
    목록
  </button>
  <button
    type="button"
    class="button-register"
    @click="updateMessage"
  >
    수정
  </button>
  <button
    type="button"
    class="button-delete"
    @click="deleteMessage"
  >
    삭제
  </button>
</div>
      
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import Swal from "sweetalert2";

export default {
  props: {
    uuid: String,
    msgId: String,
    msgName: String,
    msgContent: String,
  },
  data() {
    return {
      editableName: this.msgName,
      editableContent: this.msgContent,
      lastValidContent: this.msgContent,
      byteLimit: 90,
    };
  },
  computed: {
    currentByte() {
      const nameByte = 6;
      const modelByte = 10;
      const dateByte = 2;
      const enddateByte = 8;

      let content = this.editableContent.replace(/\$\{(.*?)\}/g, (match, varName) => {
        if (varName === "name") return "a".repeat(nameByte);
        if (varName === "model") return "a".repeat(modelByte);
        if (["YY", "MM", "DD", "hh", "mm", "ss"].includes(varName)) return "a".repeat(dateByte);
        if (varName === "enddate") return "a".repeat(enddateByte);
        return match;
      });

      let byteCount = 0;
      for (let i = 0; i < content.length; i++) {
        byteCount += content[i].match(/[가-힣]/) ? 2 : 1;
      }
      return byteCount;
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: "formedsms-read" });
    },
    getVariableRanges(text) {
      const regex = /\$\{[^}]+\}/g;
      let match;
      const ranges = [];
      while ((match = regex.exec(text)) !== null) {
        ranges.push({ start: match.index, end: match.index + match[0].length });
      }
      return ranges;
    },
    onInput(e) {
      const text = e.target.value;
      const replaced = text.replace(/\$\{(.*?)\}/g, (match, varName) => {
        if (varName === "name") return "a".repeat(6);
        if (varName === "model") return "a".repeat(10);
        if (["YY", "MM", "DD", "hh", "mm", "ss"].includes(varName)) return "a".repeat(2);
        if (varName === "enddate") return "a".repeat(8);
        return match;
      });

      let byteCount = 0;
      for (let i = 0; i < replaced.length; i++) {
        byteCount += replaced[i].match(/[가-힣]/) ? 2 : 1;
      }

      if (byteCount > this.byteLimit) {
        this.editableContent = this.lastValidContent;
      } else {
        this.lastValidContent = text;
        this.editableContent = text;
      }
    },
    onKeyDown(e) {
      const textarea = e.target;
      const pos = textarea.selectionStart;
      const text = this.editableContent;
      const ranges = this.getVariableRanges(text);

      for (const range of ranges) {
        if (
          (e.key === "Backspace" && pos > range.start && pos <= range.end) ||
          (e.key === "Delete" && pos >= range.start && pos < range.end)
        ) {
          e.preventDefault();
          this.editableContent = text.slice(0, range.start) + text.slice(range.end);
          this.$nextTick(() => {
            textarea.setSelectionRange(range.start, range.start);
          });
          return;
        }
      }
    },
    onClickTextarea(e) {
      const textarea = e.target;
      const pos = textarea.selectionStart;
      const text = this.editableContent;
      const ranges = this.getVariableRanges(text);

      for (const range of ranges) {
        if (pos > range.start && pos < range.end) {
          this.selectText(range.start, range.end);
          break;
        }
      }
    },
    selectText(start, end) {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector(".textarea-box");
        textarea.focus();
        textarea.setSelectionRange(start, end);
      });
    },
    insertVariable(variable) {
      const textarea = this.$el.querySelector(".textarea-box");
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = this.editableContent;
      this.editableContent = text.substring(0, start) + variable + text.substring(end);
      this.$nextTick(() => {
        textarea.focus();
        textarea.selectionStart = textarea.selectionEnd = start + variable.length;
      });
    },
    async updateMessage() {
      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData ? tokenData.access_token : "";

      try {
        await axios.put(
          `/sms-template/update_message/${this.uuid}`,
          {
            msg_name: this.editableName,
            msg_contents: this.editableContent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        Swal.fire({
          icon: "success",
          title: "업데이트 완료",
          text: "메시지가 성공적으로 업데이트되었습니다.",
        }).then(() => {
          this.goBack();
        });
      } catch (error) {
        console.error("업데이트 오류:", error);
        Swal.fire({
          icon: "error",
          title: "업데이트 실패",
          text: "메시지 업데이트에 실패했습니다.",
        });
      }
    },
    async deleteMessage() {
      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData ? tokenData.access_token : "";

      try {
        await axios.delete(`/sms-template/delete_message/${this.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "삭제 완료",
          text: "메시지가 성공적으로 삭제되었습니다.",
        }).then(() => {
          this.goBack();
        });
      } catch (error) {
        console.error("삭제 오류:", error);
        Swal.fire({
          icon: "error",
          title: "삭제 실패",
          text: "메시지 삭제에 실패했습니다.",
        });
      }
    },
  },
};
</script>

  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px;
    flex-wrap: nowrap; */
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px; 
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 300px; /* select box의 최대 너비 */
  }
  
  .textarea-box {
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 300px;
    height: 90px;     
    resize: none;
    
  }
  
  .button-container {
     margin-top: 60px;
     display: flex;
     gap: 30px;
   }
   
   .button-list {
     background-color: #ff9800;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
   }
   
   .button-register {
     background-color: #4caf50;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
   }

   .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
   }
   .button-list:hover {
     background-color: #e68900;
   }
 
   .button-register:hover {
     background-color: #3e8e41;
   }
   
   .button-delete:hover {
     background-color: #b81004;
   }
   
  .template-tag {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  margin-right: 10px;
  font-size: 14px;
}
  </style>
  